import * as React from 'react'
import SEO from '../components/seo'

// styles
const pageStyles = {
  color: '#232129',
  padding: '96px',
  fontFamily: '-apple-system, Roboto, sans-serif, serif',
}

// markup
const NotFoundPage = () => {
  if (
    typeof window !== 'undefined' &&
    window.location &&
    window.location.pathname?.includes('estimation')
  ) {
    window.location = '/estimation'
  }

  return (
    <main style={pageStyles}>
      <SEO title={`Erreur 404: page introuvable`} index={false} />
      <h1>404: Not Found</h1>
      <p>Cette page n'existe pas.</p>
    </main>
  )
}

export default NotFoundPage
